<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>View Report</h1>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col cols="12" md="6">
          <h2>
            Report Information -
            <strong>
              {{ formData.rpt_title }}
            </strong>
          </h2>
        </b-col>
        <b-col cols="12" md="6" class="text-right">
          <b-form-checkbox
            v-model="isToggledPivotDisplay"
            checked="true"
            name="check-button"
            switch
            inline
          >
            Toggle Pivot Display
          </b-form-checkbox>
          <b-button variant="primary">Load Report</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <span v-if="isToggledPivotDisplay">
            <DxPivot :fields="dataColumns" :items="dataSource" />
          </span>

          <span v-else>
            <DxDataGrid
              key-expr="id"
              :data-source="dataSource"
              :focused-row-enabled="true"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-borders="true"
              :row-alternation-enabled="true"
            >
              <DxColumn
                v-for="column in dataColumns"
                :key="column.name"
                :data-field="column.field"
                :caption="column.name"
                :visible="column.visible"
                :show-in-column-chooser="column.showInColumnChooser"
              />

              <DxColumnChooser :enabled="true" />
              <DxColumnFixing :enabled="true" />
              <DxSorting mode="multiple" />
              <DxFilterRow :visible="true" />
              <DxGroupPanel :visible="true" />
              <DxGrouping :auto-expand-all="false" />
              <DxHeaderFilter :visible="true" />
              <DxSearchPanel location="before" :visible="true" />

              <DxToolbar>
                <DxItem location="before" name="columnChooserButton" />
                <DxItem location="before" name="searchPanel" />
                <DxItem name="groupPanel" />
              </DxToolbar>
            </DxDataGrid>
          </span>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import reportsDesignerApi from "@api/reports_designer";

export default {
  name: "ReportsFullView",
  components: {},
  data: () => ({
    isToggledPivotDisplay: false,
    dataSource: [],
    dataColumns: [
      {
        field: "id",
        name: "ID",
        visible: false,
        showInColumnChooser: false,
      },
      {
        field: "type",
        name: "Type",
        visible: false,
        showInColumnChooser: false,
      },
    ],
    formData: {},
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadReport();
    }
  },
  methods: {
    loadReport() {
      reportsDesignerApi
        .viewResults(this.$route.query.id)
        .then(({ data }) => {
          if (data.report && data.report.data) {
            const report = data.report.data;
            if (report.header_template) {
              const headers = report.header_template.split("|");

              headers.forEach((header) => {
                var headerJSON = JSON.parse(header);
                headerJSON.area = "column";

                this.dataColumns.push(headerJSON);
              });
            }

            const getFields = (fields) => {
              const dataFields = Object.keys(fields);

              dataFields.forEach((df) => {
                const filtered = this.dataColumns.filter((x) => {
                  return x.field == df;
                });

                if (df != "type" && df != "id" && !filtered.length > 0) {
                  const column = {
                    field: df,
                    visible: false,
                    showInColumnChooser: true,
                  };

                  this.dataColumns.push(column);
                }
              });
            };

            if (report.rpt_name == "quotation") {
              if (data.quotation && data.quotation.data) {
                this.dataSource = data.quotation.data;

                if (data.quotation.data.length > 0) {
                  getFields(data.quotation.data[0]);
                }
              }
            }

            if (report.rpt_name == "rfq") {
              if (data.rfq && data.rfq.data) {
                this.dataSource = data.rfq.data;

                if (data.rfq.data.length > 0) {
                  getFields(data.rfq.data[0]);
                }
              }
            }

            this.formData = report;
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>